/*NOWY CSS*/
/* styles.css */

.main-content {
  padding: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.filter-field {
  margin-right: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.textfield {
  margin-bottom: 10px;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto; /* Enables horizontal scrolling */
}

.table-row {
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 600px) {
  .table-container {
      margin: 0;
  }

  .table-cell, .table-header {
      font-size: 14px; /* Adjust font size for smaller screens */
  }
}


.main-content {
  padding: 16px;
}

.button-group {
  margin-bottom: 16px;
}

.filter-container {
  margin-bottom: 16px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}


.filter-field {
  margin-right: 16px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.textfield {
  margin-bottom: 16px;
}

.table-container {
  margin-top: 16px;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}


/**/
.main-content {
  padding: 16px;
}

.button-group {
  margin-bottom: 16px;
}

.filter-container {
  margin-bottom: 16px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}

.filter-container.show {
  max-height: 500px; /* Ustaw max-height na odpowiednią wartość */
  height: auto; /* Automatyczne dostosowanie wysokości */
  opacity: 1; /* Upewnij się, że jest widoczny */
}

.filter-field {
  margin-right: 16px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.textfield {
  margin-bottom: 16px;
}

.table-container {
  margin-top: 16px;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}





/* Global styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Sidebar styles */
.sidebar {
  width: 240px;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.sidebar .logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.sidebar .logo img {
  max-height: 100%;
}

.sidebar .menu {
  flex-grow: 1;
}

.sidebar .menu-item {
  padding: 16px;
}

.sidebar .menu-item:hover {
  background-color: #f0f0f0;
}

.sidebar .logout-button {
  margin: auto 16px 16px;
}

/* Main content styles */
.main-content {
  flex-grow: 1;
  padding: 24px;
}

/* Form styles */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.form-container .textfield {
  width: 100%;
}

/* Table styles */
.table-container {
  margin-top: 24px;
}

.table-container .table {
  width: 100%;
}

.table-container .table-head {
  background-color: #f5f5f5;
}

.table-container .table-row:hover {
  background-color: #f0f0f0;
}
