/* Ogólne style */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Sidebar */
.sidebar {
  background-color: #333;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Logo */
.sidebar .logo {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #444;
}

.sidebar .logo img {
  max-width: 100px;
}

/* Linki i przyciski w sidebarze */
.sidebar a,
.sidebar button {
  color: white;
  text-decoration: none;
}

.sidebar .active {
  background-color: #444;
}

.sidebar button {
  background-color: #ec6b23;
  color: white;
  border: none;
}

/* Przyciski w głównym widoku */
button {
  background-color: #ec6b23;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 8px;
  cursor: pointer;
  border-radius: 4px;
}

/* Linki */
a {
  color: #ec6b23;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Tabela */
.table-container {
  margin-top: 16px;
}

.table-row {
  transition: background-color 0.3s ease;
}

.table-row:hover {
  background-color: #f5f5f5;
}

/* Formularze */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.textfield {
  margin-bottom: 16px;
}

.textfield input {
  border-color: #ec6b23;
}

/* Dialogi */
.dialog-title {
  background-color: #ec6b23;
  color: white;
}

.dialog-actions button {
  background-color: #ec6b23;
  color: white;
}

.dialog-actions button:hover {
  background-color: #d95b20;
}

/* Responsive */
@media (max-width: 600px) {
  .sidebar {
      width: 100%;
      height: auto;
      position: relative;
  }

  .sidebar .logo {
      padding: 8px;
  }

  .main-content {
      padding: 8px;
  }
}








.main-content {
  padding: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.filter-field {
  margin-right: 10px;
  margin-bottom: 10px;
}

.form-container {
  margin-top: 20px;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.table-row {
  cursor: pointer;
}

.search-field {
  margin-bottom: 20px;
}

